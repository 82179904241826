.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.backgroundImageLogin {
  background-image: url(./Images/PinkBack.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.loadingMessage {
  font-size: 40px;
  font-weight: bold;
  margin-top: 10px;
}

.dcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button2:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.datePickerButton {
  background-color: #9dcefe;
  border-radius: 8px;
  border-color: #5eadff;
  width: 80%;
  outline: none;
  padding: 5px 5px;
}

.Boomtowntittle {
  font-size: 40px;
  font-weight: bold;
  text-shadow: -3px 0 rgb(255, 255, 255), 0 2px rgb(255, 255, 255),
    1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
  font-family: sans;
  color: rgb(255, 0, 0);
  text-align: center;
  background-color: rgb(0, 0, 0);
  border: solid rgb(0, 0, 0);
}

.mobiledatefont {
  font-size: 15px !important;
  font-weight: bold !important;
}

.activeResult {
  border: 5px solid rgb(60, 252, 60);
  border-radius: 50%;
  -webkit-filter: grayscale(0%) !important;
  filter: grayscale(0%) !important;
}

.ButtonLogin {
  margin-top: 40px;
  width: 200px;
  height: 45px;
  border-radius: 5px;
  border-width: 1;
  background-color: black;
  border-color: lightblue;
  justify-content: center;
}

main {
  margin: auto;
  max-width: 960px;
}

.mainPageToggleLogo {
  height: 55px;
  width: 55px;
  cursor: pointer;
  -webkit-filter: grayscale(100%);
  filter: grayscale(1000%);
}

.table-container {
  margin: 10px;
  padding: 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

tr.expandable > td {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.2);
  padding: 0;
}

tr.expandable > td > .inner {
  margin: 15px;
  overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.example::-webkit-scrollbar {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.containers {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 320px;
  text-align: center;
  border: 10px solid #101116;
  background: #101116;
  border-radius: 5px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.keypad {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(60px, auto);
}

.CalculatorResult {
  height: 75px;
  width: 280px;
  background-color: #10111600;
  border: 0px;
  color: white;
  text-align: right;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 1px;
}

#clear {
  grid-column: 1/3;
  grid-row: 1;
  color: #262834;
}

#result {
  grid-column: 3/5;
  grid-row: 5;
  color: #262834;
}

#backspace {
  color: #262834;
}

.ButtonCalculate {
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #262834;
  color: white;
  font-weight: 500;
}

.border {
  background-color: rgb(0, 0, 0);
  color: white;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.border2 {
  background-color: grey;
  font-weight: 500;
  text-align: center;
  color: black;
  font-size: 25px;
}

.border3 {
  background-color: none;
  font-weight: 500;
  text-align: center;
  color: black;
  font-size: 25px;
  border: (204, 204, 204);
}

.resulttop {
  font-size: 28px;
  color: #303030;
  font-weight: 700;
  text-align: center;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  padding: 2px 0;
}

.resultbottom {
  text-align: center;
  font-size: 24px;
  color: #000;
  width: 20%;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  padding: 3px 0;
  font-weight: bold;
}

.resultbottom2 {
  text-align: center;
  font-size: 24px;
  color: #000;
  padding: 3px 0;
  border: none;
  outline: none;
  margin-left: 10;
}

.Damacaitittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(31, 33, 163);
  border: solid black;
}

/* ToTo */
.Tototittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(194, 25, 25);
  border: solid black;
}

/* Cash */
.Cashtittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(34, 116, 34);
  border: solid black;
}

/* Magnum */
.Magnumtittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: black;
  background-color: rgba(255, 230, 0, 0.658);
  border: solid black;
}

/* Sabah88 */
.Sabahtittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(4, 0, 255);
  border: solid black;
}

/* Singapora */
.Sgtittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(34, 201, 243);
  border: solid black;
}

/* Stc */
.Stctittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(34, 116, 34);
  background-color: rgb(255, 187, 0);
  border: solid black;
}

.Gdtittle {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(255, 0, 0);
  border: solid black;
}

.ButtonCalculate:hover {
  background: #56cbcd;
  color: black;
  box-shadow: 0 12px 16px 0 rgba(185, 185, 185, 0.24),
    0 17px 50px 0 rgba(196, 196, 196, 0.19);
}

.otherColor {
  background: #56cbcd !important;
}

.otherColor:hover {
  background: #262834 !important;
  color: white !important;
}
